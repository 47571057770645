
import './common.css'
import { Link } from 'react-router-dom'; 

 const footer = () => {


    return (
        <>
        <footer id='t-footer' className='bg-[#6D6D6D] lg:h-[218px] h-[140px] sm:h-[120px]'> 
            <div className='lg:pl-10 lg:pr-10'>
                <div id='footer-text1' className='text-white   lg:flex lg:justify-center lg:pt-[32px] pt-[20px] lg:text-[25px] text-[10px] sm:flex sm:justify-center sm:text-[12px] ' >
                    <p className="lg:pr-[29px] lg:ml-0 ml-[21px]">(주)정션메드</p>
                    <p className="lg:pr-[29px]  lg:ml-0 ml-[21px]  ">본사: 강원특별자치도 춘천시 남산면 버들1길 130, 4층 </p>
                    <p className="lg:pr-[29px]  lg:ml-0 ml-[21px] ">대표자: 박지민  </p>
                    <p className=" lg:ml-0 ml-[21px] ">사업자등록번호: 440 86 02331  </p>
                </div>
                <div id='footer-text2' className='text-white  lg:flex justify-center lg:pt-[12px]  lg:ml-0 ml-[21px] sm:flex sm:text-[12px]' >
                    <p id='footer-text2'className="lg:pr-[29px] lg:text-[25px] text-[10px] sm:pr-4 sm:text-[12px] ">02-6951-2788</p>
                    <p id='footer-text2'className="lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px]">Copyright 2024주식회사 정션메드 All rights reserved </p>
                    <Link to='/agreement-service' className='lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px] cursor-pointer' >서비스 이용약관</Link>
                    <Link to='/agreement-privacy'className='lg:pr-[29px] lg:text-[25px] text-[10px] sm:text-[12px] cursor-pointer'>개인정보처리방침</Link>
                </div>
            </div>
        </footer>
        </>
   
    )
  }

  export default footer